<template>
  <insights />
</template>
<script>
import Insights from "@/components/Insights";

export default {
  data(){
    return {
      }
  },
  methods:{
  },
  components:{
    Insights
  }
}
</script>
