<template>
  <div>
     <div>
         <div class="md-title">Insights</div>
       <div class="flexrow">
         <md-card v-if="users" class="card" md-with-hover>
           <md-card-header>
             <md-card-header-text>
               <div class="md-title">
                 {{users}}
               </div>
               <div class="md-subhead">
                 distinct users visited (last month)
               </div>
             </md-card-header-text>
             <md-card-media>
              <md-icon class="md-size-2x">web</md-icon>
             </md-card-media>
           </md-card-header>
         </md-card>
         <md-card v-if="averageVisits" class="card" md-with-hover>
           <md-card-header>
             <md-card-header-text>
               <div class="md-title">
                 {{averageVisits.toFixed(2)}}
               </div>
               <div class="md-subhead">
                 sites are visited by the average user (last month)
               </div>
             </md-card-header-text>
             <md-card-media>
               <md-icon class="md-size-2x">functions</md-icon>
             </md-card-media>
           </md-card-header>
         </md-card>
<!--         <md-table-cell>-->
<!--           Distinct devices in the last month: {{users}}-->
<!--         </md-table-cell>-->
       </div>
       <div>
         <div >
           <line-chart
             style="height: 400px;"
             v-if="viewsChartData"
             :chartdata="viewsChartData"
             :options="viewsChartOptions"/>
         </div>
       </div>
       <div class="flexrow">
         <md-card class="card" md-with-hover>
           <md-card-header>
             <div class="md-title">
               Visits by Country
             </div>
             <div class="md-subhead">
               (Last Month)
             </div>
           </md-card-header>
           <md-card-content>
             <pie-chart
               style="height: 400px;"
               v-if="countryChartData"
               :chartdata="countryChartData"
               :options="countryChartOptions"/>
           </md-card-content>
         </md-card>
         <md-card class="card" md-with-hover>
           <md-card-header>
             <div class="md-title">
               Visits by Site
             </div>
             <div class="md-subhead">
               (Last Month)
             </div>
           </md-card-header>
           <md-card-content>
             <pie-chart
               style="height: 400px;"
               v-if="siteChartData"
               :chartdata="siteChartData"
               :options="siteChartOptions"/>
           </md-card-content>
         </md-card>
         <md-card class="card" md-with-hover>
           <md-card-header>
             <div class="md-title">
               Mobile vs. Desktop
             </div>
             <div class="md-subhead">
               (Last Month)
             </div>
           </md-card-header>
         <md-card-content>
           <pie-chart
             style="height: 400px;"
             v-if="mobileChartData"
             :chartdata="mobileChartData"
             :options="mobileChartOptions"/>
         </md-card-content>
         </md-card>
       </div>
     </div>
  </div>
</template>

<script>
import axios from "axios";
import config from '@/config.js'
import colormap from 'colormap'

import LineChart from './charts/line.vue'
import PieChart from './charts/pie.vue'

export default {
    name: 'Analysis',
    components: {LineChart, PieChart},
    data: () => ({

      viewsChartData: null,
      viewsChartOptions: null,

      countryChartData: null,
      countryChartOptions: null,

      siteChartData: null,
      siteChartOptions: null,

      mobileChartData: null,
      mobileChartOptions: null,

      users: 0,
      averageVisits: 0,
      countries:[],
    }),
    methods: {
      getViews: async function () {
        const data = await axios.get(config.LOGGER + '/data/views/' + config.LOGGER_SALT)
          .then(function (response) {
            let data = response.data
            return data.map(item => (
              {
                x: item._id,
                y: item.count
              }
            ))
          })
          .catch(function (error) {
            alert("While rendering the Views Chart, an Error occurred, please contact your web admin. \n" + error);
          });
        this.viewsChartData = {
          datasets: [{
            data: data,
            label: 'Views per day',
            backgroundColor: '#26C6DA',
          }]
        }
        this.viewsChartOptions = {
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                unit: 'day'
              }
            }]
          },
          responsive: true,
          maintainAspectRatio: false
        }
      },
      getUsers: function () {
        const me = this
        axios.get(config.LOGGER + '/data/users/' + config.LOGGER_SALT)
          .then(function (response) {
            me.users = response.data.uniqueUsers
          })
          .catch(function (error) {
            alert("An Error occurred, please contact your web admin. \n" + error);
          });
      },
      getAverageVisits: function () {
        const me = this
        axios.get(config.LOGGER + '/data/uniqueAverage/' + config.LOGGER_SALT)
          .then(function (response) {
            me.averageVisits = response.data[0].average
          })
          .catch(function (error) {
            alert("An Error occurred, please contact your web admin. \n" + error);
          });
      },
      getCountries: async function () {
        const data = await axios.get(config.LOGGER + '/data/countries/' + config.LOGGER_SALT)
          .then(function (response) {
            return response.data.sort((a, b) => (a.count > b.count) ? -1 : 1)
          })
          .catch(function (error) {
            alert("An Error occurred, please contact your web admin. \n" + error);
          });

        this.countryChartData = {
          labels: data.map(item => item._id),
          datasets: [
            {
              backgroundColor: colormap({
                colormap: 'winter',
                nshades: data.length<5 ? 5 : data.length,
                format: 'hex',
                alpha: 0.8
              }),
              label: "Visits by Country",
              data: data.map(item => item.count)
            }
          ]
        }
        this.countryChartOptions = {
          cutoutPercentage: 60,
          'animation.animateScale': true,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      getSites: async function () {
        const  dataRaw = await axios.get(config.LOGGER + '/data/sites/' + config.LOGGER_SALT)
          .then(function (response) {
            return response.data
              .map(d => ({_id: d._id.toLowerCase(), count: d.count}))
          })
          .catch(function (error) {
            alert("An Error occurred, please contact your web admin. \n" + error);
          });

        let data = []
        dataRaw.reduce(function(res, value) {
          if (!res[value._id]) {
            res[value._id] = { _id: value._id, count: 0 };
            data.push(res[value._id])
          }
          res[value._id].count += value.count;
          return res;
        }, {});
        const dataPoints = data.map(d => d.count).reduce((a,b) => a + b, 0)
        data = data.filter(a => (a.count/dataPoints) > .005)
        let dataOther = dataPoints - data.map(d => d.count).reduce((a,b) => a + b, 0)

        data = [...data, {_id: "Other", count: dataOther}]
        this.siteChartData = {
          labels: data.map(item => item._id),
          datasets: [
            {
              backgroundColor: colormap({
                colormap: 'winter',
                nshades: data.length<5 ? 5 : data.length,
                format: 'hex',
                alpha: 0.8
              }),
              label: "Visits by Site",
              data: data.map(item => item.count)
            }
          ]
        }
        this.siteChartOptions = {
          cutoutPercentage: 60,
          'animation.animateScale': true,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      getMobileDesktop: async function () {
        const data = await axios.get(config.LOGGER + '/data/mobileDesktop/' + config.LOGGER_SALT)
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            alert("An Error occurred, please contact your web admin. \n" + error);
          });

        this.mobileChartData = {
          labels: data.map(item => item._id),
          datasets: [
            {
              backgroundColor: ["#80DEEA","#DAF7A6"],
              label: "Visits by Country",
              data: data.map(item => item.count)
            }
          ]
        }
        this.mobileChartOptions = {
          cutoutPercentage: 60,
          'animation.animateScale': true,
          responsive: true,
          maintainAspectRatio: false
        }
      },
    },
    mounted() {
      this.getCountries()
      this.getViews()
      this.getSites()
      this.getMobileDesktop()
      this.getUsers()
      this.getAverageVisits()
    }

  }

</script>
<style scoped>
.flexrow{
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card{
  min-width: 200px;
  max-width:80vw;
}
</style>
